
import { Component, Vue } from 'vue-property-decorator';
import LocaleChanger from './LocaleChanger.vue';
import store from '@/store';
import db from '@/db';
import NotificationListener from "@/components/NotificationListener/Components/NotificationListener.vue"

@Component({
  components: {
    LocaleChanger,
    NotificationListener,
  },
})
export default class Navbar extends Vue {
  isShowSearch = true;
  isShowSetting = true;
  searchQuery = '';
  searchEnabled = false;
  mobileData = false;
  tab = 0;
  skip = 0;
  showLoginDialog = false;
  showRegisterDialog = false;
  scrollTargetRef = null;
  lastVisible = null;

  onDrawerClick() {
    this.$emit('onDrawerClick');
  }

  onlogout() {
    this.logout();
    store.replaceState({});
    this.$router.push('/login');
    setTimeout(() => {
      this.$router.go(0);
    }, 1500);
  }

  logout() {
    store.state.loggedIn = false;
    store.state.profile = {};
    store.commit("SET_USER", store.state.profile);
  }

  setLanguage(newValue: string) {
    store.commit('UPDATE_LANGUAGE', newValue)
  }

  get language() {
    return store.state.language
  }

  get initialization() {
    return store.state.initialization
  }

  get profile() {
    return store.state.user.profile
  }
  
  get loggedIn() {
    return store.state.user.loggedIn
  }
}
