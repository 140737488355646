
import { Component, Vue } from 'vue-property-decorator';
import store from '@/store';
import db from '@/db';
import Utils from '@/components/utils/Utils';
import { NotificationType } from "../Models/NotificationType"

@Component({
  components: {
  },
})
export default class NotificationListener extends Vue {
  showRegisterDialog = false;
  notificationDialog = false;
  notifications = [];
  newNotifications = [];
  scrollTargetRef = null;
  lastVisible = null;
  loadingNotifications = false;
  allNotificationsAreReadMessage = 'You are up to date'
  fetchSize = null

  mounted() {
    this.getNewNotifications();
  }

  async markAllAsRead() {
    for (const newNotification of this.newNotifications) {
      await db
        .collection(`tenants/${store.state.tenantID}/notifications`)
        .doc(newNotification.ID)
        .set({opened: true,},{ merge: true });
    }
  }

  getNewNotifications() {
    db.collection(`tenants/${store.state.tenantID}/notifications`)
      .where('user', '==', store.state.currentUser.ID)
      .where('opened', '==', false)
      .orderBy('createdAt', 'desc')
      .limit(11)
      .onSnapshot((querySnapshot) => {
        this.notifications = [];
        this.newNotifications = [];
        querySnapshot.forEach((doc) => {
          this.newNotifications.push(doc.data());
          this.lastVisible = this.newNotifications[this.newNotifications.length - 1] && this.newNotifications[this.newNotifications.length - 1]["createdAt"] ? this.newNotifications[this.newNotifications.length - 1]["createdAt"] : null;
        });
      });
  }

  // getFirstNotifications() {
  //   this.notifications = [];
  //   db.collection(`tenants/${store.state.tenantID}/notifications`)
  //     .where('user', '==', store.state.currentUser.ID)
  //     .orderBy('createdAt')
  //     .limit(10)
  //     .get()
  //     .then((documentSnapshots) => {
  //       documentSnapshots.forEach((doc) => {
  //           this.notifications.push(doc.data());
  //       });
  //       this.lastVisible = this.notifications[this.notifications.length - 1] && this.notifications[this.notifications.length - 1]["ID"] ? this.notifications[this.notifications.length - 1]["ID"] : null;
  //     });
  // }

  getNotificationsOnScroll(index: number, done: (stop) => void, stop: () => void)  {
    setTimeout(() => {
        if (this.lastVisible == undefined) {
            done(true);
            return;
        }
        if (this.newNotifications.length < 11 || this.fetchSize === 0) {
            done(true);
            return;
        }
        db.collection(`tenants/${store.state.tenantID}/notifications`)
        .where('user', '==', store.state.currentUser.ID)
        .where('opened', '==', false)
        .orderBy('createdAt', 'desc')
        .startAfter(this.lastVisible)
        .limit(11)
        .get()
        .then((documentSnapshots) => {
          this.fetchSize = documentSnapshots.size
          const listWithIDs = []
          for(const notification of this.newNotifications) {
            if(!listWithIDs.includes(notification.ID)) {
              listWithIDs.push(notification.ID)
            }
          }
          documentSnapshots.forEach((doc) => {
            const docData = doc.data()
            if(!listWithIDs.includes(docData.ID)) {
              this.newNotifications.push(doc.data());
            }
            this.lastVisible = this.newNotifications[this.newNotifications.length - 1] && this.newNotifications[this.newNotifications.length - 1]["createdAt"] ? this.newNotifications[this.newNotifications.length - 1]["createdAt"] : null;
          });
        });
      done(false);
    }, 1000);
  }

  convertDate(createdAt: any) {
    if (!createdAt) return '';
    const date = new Date(createdAt.seconds * 1000);
    return date.toLocaleString('nl-Nl');
  }

  executeNotificationAction(notification: any) {
    if (notification.action && notification.action.type == 'openRecord') {
      this.openRecordAction(notification);
    } else if (notification.action && notification.action.type == 'openURL') {
      this.openURLAction(notification);
    } else {
      this.notificationOpened(notification);
    }
  }

  async openRecordAction(notification: any) {
    if (notification.action && notification.action.module && notification.action.record) {
      await this.notificationOpened(notification);
      const path = `/modules/${notification.action.module}/edit/${notification.action.record}`
      if(path == this.$router.currentRoute.path) return
      this.$router.push(path);
      this.$router.go(0)
    }
  }

  async openURLAction(notification: any) {
    if (notification.action && notification.action.URL) {
      window.open(`https://${notification.action.URL}`, '_blank');
      await this.notificationOpened(notification);
    }
  }

  async notificationOpened(notification: any) {
    await db
      .collection(`tenants/${store.state.tenantID}/notifications`)
      .doc(notification.ID)
      .set({opened: true,}, { merge: true });
  }

  getNotificationTitle(notification) {
    let title = 'New message'
    if(notification.relatedTo.name && notification.type === NotificationType.STATUS_CHANGE) title = `${this.$t('notifications.headers.statusChange')} ${this.$t('notifications.headers.in')} ${notification.relatedTo.name}`
    else title = `${this.$t('notifications.headers.statusChange')}`
    return title
  }

  getAllReadMessage() {
    const translations = {
        'nl-NL': [
            ["🦁", "Notificaties getemd!"],
            ["🌟", "Wat een rust \n Geen nieuwe notificaties!"],
            ["📭", "Inbox leeg \n Geen nieuwe notificaties!"],
            ["📦", "Alles bijgewerkt \n Geen nieuwe notificaties!"],
            ["💰", "Notificaties buitgemaakt!"],
            ["🍔", "Alle notificaties verslonden!"],
            ["🍳", "Notificaties als ontbijt!"],
            ["🕒", "Tijd om te relaxen \n Geen nieuwe notificaties!"],
            ["💤", "Slaaptijd \n Geen meldingen om wakker van te liggen!"],
            ["👷", "Helm en schoen \n Niets meer te doen!"]
        ],
        'en-EN': [
            ["🍟", "Noti-fry-cations fried and crispy!"],
            ["😄", "Checked, wrecked, and tickled!"],
            ["🛌", "All alerts tucked into bed!"],
            ["🤠", "Wrangled and dangled!"],
            ["🍕", "Notifications devoured, nom nom!"],
            ["🕺", "Notifications moonwalked away!"],
            ["🦋", "Every ping's got its wings!"],
            ["🌱", "Notifications: Mowed the lawn!"],
            ["🐂", "All notifications rodeo-ed!"],
            ["🧶", "Notifications: Knitted into a sweater!"]
        ],
        'fr-FR': [
            ["🚂", "Notifications: Tous à bord!"],
            ["🦁", "Alertes apprivoisées et amusées!"],
            ["🍽️", "Messages: Un festin!"],
            ["🐣", "Tout est bien qui finit bien!"],
            ["🚀", "Tout est sous contrôle!"],
            ["🎪", "Alertes: Domptées et déployées!"],
            ["🎮", "Messages: Un jeu d'enfant!"],
            ["💼", "Une affaire rondement menée!"],
            ["🏖️", "Alertes: En mode détente!"],
            ["🐟", "Messages: Comme un poisson dans l'eau!"]
        ],
        'de-DE': [
            ["🎩✨", "Benachrichtigungen im Zauberland!"],
            ["😄", "Alles erledigt und gelacht!"],
            ["🕺", "Geprüft und verführt!"],
            ["🎪", "Nachrichten: Ein Zirkus!"],
            ["🤠", "Benachrichtigungen: Gebändigt!"],
            ["😆", "Alles im Griff und gelacht!"],
            ["🏇", "Hopp, hopp!"],
            ["🛌", "Schlafenszeit!"],
            ["🍳", "Gekocht und genossen!"],
            ["🎉", "Alles gelesen und gefeiert!"]
        ],
        'es-ES': [
            ["😄", "conquistadas y felices!"],
            ["🤠", "¡Doma y diversión!"],
            ["🎣", "Mensajes cazados y atrapados!"],
            ["🎉", "Notificaciones en modo fiesta!"],
            ["📋", "Todo revisado y notificado!"],
            ["🏃‍♂️", "¡Saltaron la cuerda!"],
            ["🎊", "¡Una fiesta de piñata!"],
            ["🧒", "¡Un juego de niños!"],
            ["🎩✨", "¡Magia de desaparición!"],
            ["🎭", "¡Fiesta de disfraces!"]
        ]
    }
    const randomInt = Utils.getRandomInt(0, 9)
    this.allNotificationsAreReadMessage = translations[store.state.language][randomInt]
  }
}
