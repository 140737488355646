

import Printjs from "print-js";

export default {
  name: "Test",
  props: {doPrint: null, downloadableURL: null},
  data: function () {
    return {
    }
  },
  methods: {
    async runPrinting() {
      if (this.downloadableURL) {
        Printjs({
          printable: this.downloadableURL,
          type: "pdf"
        });
      }
    }
  },
  watch: {
    doPrint() {
      this.runPrinting();
    }
  }
}
