import { render, staticRenderFns } from "./NotificationListener.vue?vue&type=template&id=5848422f&scoped=true&"
import script from "./NotificationListener.vue?vue&type=script&lang=ts&"
export * from "./NotificationListener.vue?vue&type=script&lang=ts&"
import style0 from "./NotificationListener.vue?vue&type=style&index=0&id=5848422f&prod&lang=scss&"
import style1 from "./NotificationListener.vue?vue&type=style&index=1&id=5848422f&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5848422f",
  null
  
)

export default component.exports
import {QBtn,QIcon,QBadge,QMenu,QCard,QItem,QItemSection,QTooltip,QList,QInfiniteScroll,QCardSection,QSpinnerDots,QTabs,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBtn,QIcon,QBadge,QMenu,QCard,QItem,QItemSection,QTooltip,QList,QInfiniteScroll,QCardSection,QSpinnerDots,QTabs})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
