import { render, staticRenderFns } from "./Navbar.vue?vue&type=template&id=745c05ed&scoped=true&"
import script from "./Navbar.vue?vue&type=script&lang=ts&"
export * from "./Navbar.vue?vue&type=script&lang=ts&"
import style0 from "./Navbar.vue?vue&type=style&index=0&id=745c05ed&prod&lang=scss&"
import style1 from "./Navbar.vue?vue&type=style&index=1&id=745c05ed&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "745c05ed",
  null
  
)

export default component.exports
import {QBtn,QIcon,QAvatar,QMenu,QCard,QCardSection,QSeparator,QItem,QTabs,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBtn,QIcon,QAvatar,QMenu,QCard,QCardSection,QSeparator,QItem,QTabs})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
