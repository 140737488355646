
  import { Vue, Component, Prop } from "vue-property-decorator";
  import store from "@/store";

  @Component({})
  export default class LocaleChanger extends Vue {
    @Prop(String) language;

    langs: Array<string> = ["en-GB", "nl-NL", "fr-FR", 'de-DE'];
    database = 'dev';
    webAppVersion = null;

    mounted() {
      this.webAppVersion = store.state.webAppVersion
    }

    get selectedLanguage() {
      return this.language;
    }

    set selectedLanguage(value: string) {
      this.$emit("setLanguage", value);
    }

    get onDatabase() {
      return this.database;
    }

    set onDatabase(value: string) {
      this.database = value;
      localStorage.setItem("databaseProd", value);
    }

    beforeMount() {
      this.database = localStorage.getItem("databaseProd") || "prod";
    }

  }
