
import Navbar from "../components/Parts/Navbar";
import PrintDirectly from "@/components/Settings/Sections/Templates/PDFgenerator/Components/PrintDirectly.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import {EventBus} from "@/event-bus";
import {useModulesStore} from "../components/Modules/Store/ModuleStore"
import store from "@/store"
import db from "../db";

export default {
  name: "SideMenu",
  components: { Navbar, PrintDirectly, DynamicForm: () => import('@/components/Forms/DynamicForm') },
  data: () => ({
    modalData: {},
    open: false,
    drawer: false,
    miniState: false,
    pinnedModuleList: false,
    imagesBackgroundAppUrl: '',
    modulesStore: null,
    users: [],
    company: {},
  }),
  async created() {
    setTimeout(async () => {
      await this.setupIntercom()
    }, 1500);
  },
  computed: {
    ...mapState(["initialization", "alertMessage", "customMessage", "dynamicModules", "tenantID", "PORTAL", "guard"]),
    ...mapGetters("settings", ["designIcons", "designCss"]),
    ...mapState("actions", ["doPrint", "downloadableURL"]),
    ...mapGetters(["getPreferences"]),
    dynamicMenus() {
      return Object.values(this.dynamicModules).filter((a)=>{
        return a.hidden !== true && this.guard.checkModule(a.id) && this.checkLicense(a) && !a.id.includes('admin_');
      }).sort((a, b) => {
        return a.order - b.order;
      });
    },
    isPortal() {
      return Boolean(this.PORTAL);
    },
    isAllDataLoaded() {
      return this.isModulesLoaded && this.colorLoaded && this.showFormSettingLoaded;
    },
    colorLoaded() {
      return true;
    },
    iconsLoaded() {
      return false;
    },
    showFormSettingLoaded() {
      return true;
    },
    hiddenParts() {
      return {};
    },
    currentRouteName() {
      return this.$route.name;
    },
    tenantLoaded() {
      return this.tenantID;
    },
    isModulesLoaded() {
      return this.initialization;
    },
    portalCss() {
      return '<style>' + this.designCss + '</style>'
    }
  },
  methods: {
    ...mapActions(["resetAlertMessage"]),
    ...mapActions("user", { logout: "logout" }),
    ...mapActions(["setPreferences"]),
    async setPinnedModuleList() {
      this.pinnedModuleList = !this.pinnedModuleList;
      this.setPreferences({pinnedModuleList: this.pinnedModuleList})
    },
    async getPinnedModuleList() {
      const preferences = await this.getPreferences;
      return preferences && preferences.pinnedModuleList ? preferences.pinnedModuleList : false
    },
    checkMinistate() {
      this.miniState = !this.pinnedModuleList
    },
    async setupIntercom() {
      this.company = await this.getCompany()
      this.users = await this.getCompanyUsers()
      this.$intercom.shutdown();
      const intercom = {
        user_id: store.state.currentUser.ID,
        name: store.state.currentUser.name,
        email: store.state.userProfile.email,
        company: {
          company_id: store.state.tenantID,
          created_at: 1694531169,
          name: this.company.name,
          size: this.users.length
        }
      }
      this.$intercom.boot(intercom);
    },
    async getCompany() {
      return await db.collection('tenants/').doc(store.state.tenantID).get().then((doc) => {
        return doc.data()
      })
    },
    async getCompanyUsers() {
      return await db.collection(`tenants/${store.state.tenantID}/modules/users/records`).orderBy('ID').startAfter(0).get().then(sn => { return sn.docs.map(doc => doc.data()) })
    },
    getMenuPath(menu) {
      let p = "";
      if(menu.id == "planboard" || menu.id == "dashboards") {
        p = '/' + menu.id
      } else {
        p = '/modules/' + menu.id
      }
      return p;
    },
    checkLicense(a) {
      if(a.needUserLicense){
        if(this.$store && this.$store.state && this.$store.state.licenseManager && this.$store.state.licenseManager.licenses && this.$store.state.licenseManager.licenses[a.id] && this.$store.state.licenseManager.licenses[a.id].active){
          return true
        }
        return false
      }
      return true
    },
    showAlert() {
      const closeTxt = 'x';
      let color = 'positive';
      let message = '';
      switch (this.alertMessage) {
        case "custom":
          color = this.customMessage.color || color;
          message = this.customMessage.text || '';
          break;
          case "add":
          message = this.$t("common.messages.recordCreated");
          break;
        case "update":
          message = this.$t("common.messages.recordChanged");
          break;
        case "done":
          message = this.$t("common.messages.actionDone");
          break;
        case "delete":
          color = 'warning';
          message = this.$t("common.messages.recordDeleted");
          break;
        case "error":
          color = 'negative';
          message = this.$t("common.messages.operationNotCompleted");
          break;
        case "wrong_date":
          color = 'negative';
          message = this.$t("common.messages.wrongDate");
          break;
        case "not_valid":
          color = 'negative';
          message = this.$t("common.messages.operationNotValidated");
          break;
        case "not_found":
          color = 'negative';
          message = this.$t("common.messages.operationNotFound");
          break;
        case "over_size":
          color = 'negative';
          message = this.$t("common.messages.attachmentOverSize");
          break;
        case "no_email_settings":
          color = 'warning';
          message = this.$t("common.messages.noEmailSettings");
          break;
        case "no_access":
          color = 'negative';
          message = this.$t("common.messages.no_access");
          this.$router.push('/login');
          break;
        case "no_permission":
          color = 'negative';
          message = this.$t("common.messages.no_permission");
          break;
        case "locked":
          color = 'warning';
          message = this.$t("common.messages.locked");
          break;
        case "lockedOnDelete":
          color = 'warning'
          message = this.$t('common.messages.lockedOnDelete');
          break;
        case "unlocked":
          color = 'warning';
          message = this.$t("common.messages.unlocked");
          break;
        case "none":
          this.resetAlertMessage();
          break;
        default:
          this.resetAlertMessage();
      }
      if (this.alertMessage != "none" && message) {
        this.$q.notify({message, color, actions: [{ label: closeTxt, color: 'white', handler: () => {} }] });
      }
      this.resetAlertMessage();
    },
    resetModalForm() {
      this.open = false;
    },
    isActiveItem (value) {
      if(this.$route.path === '/planboard' && value === 'planboard') return true
      if(this.$route.path === '/dashboards' && value === 'dashboards') return true
      return this.$route.params.moduleName === value
    },
    isBeforeActiveItem (value) {
      const menu = this.dynamicMenus.map(e => e.id)
      let activeItemName = ""
      if(this.$route.path === '/planboard') {
        activeItemName = "planboard"
      } else if (this.$route.path === '/dashboards') {
        activeItemName = "dashboards"
      } else {
        activeItemName = this.$route.params.moduleName
      }
      const numberActive = menu.indexOf(activeItemName)
      const numberCurrent = menu.indexOf(value)
      return numberActive - numberCurrent === 1 && this.$route.path !== '/' && this.$route.name !== 'settings' && !this.$route.path.includes('settings')
    },
    isAfterActiveItem (value) {
      const menu = this.dynamicMenus.map(e => e.id)
      let activeItemName = ""
      if(this.$route.path === '/planboard') {
        activeItemName = "planboard"
      } else if (this.$route.path === '/dashboards') {
        activeItemName = "dashboards"
      } else {
        activeItemName = this.$route.params.moduleName
      }
      const numberActive = menu.indexOf(activeItemName)
      const numberCurrent = menu.indexOf(value)
      return numberCurrent - numberActive === 1 && this.$route.path !== '/' && this.$route.name !== 'settings' && !this.$route.path.includes('settings') && !this.$route.path.includes('admin_')
    },
    getItemClass (value) {
      let activeItem = ""
      if (value == 'planboard') {
        activeItem = this.$route.path.includes('/planboard')
      } else if (value == 'dashboards') {
        activeItem = this.$route.path.includes('/dashboards')
      } else {
        activeItem = this.isActiveItem(value)
      }
      const menuItemActive = activeItem ? 'menu-item-active ' : ''
      const textWhite = !activeItem ? 'text-white ' : ''
      const menuItemBeforeActive = this.isBeforeActiveItem(value) ? 'menu-item-before-active ' : ''
      const menuItemAfterActive = this.isAfterActiveItem(value) ? 'menu-item-after-active ' : ''
      return menuItemActive + textWhite + menuItemBeforeActive + menuItemAfterActive
    },
    getNameWordsArray (name) {
      return name.split(" ")
    },
    getMenuItemSecondRow (name) {
      return this.getNameWordsArray(name).splice(1).join(' ')
    },
    getItemName(item) {
      const translationKey = `modules.${item.id}.pluralName`
      if(store.state.fieldTranslations && store.state.fieldTranslations[translationKey]) {
        return store.state.fieldTranslations[translationKey]
      }
      return item.pluralName || item.name || 'no name';
    },
    checkPortal() {
      // TODO use the route middleware in the future
      const uri = window.location.href;
      if (this.PORTAL && !uri.includes('/views') && !uri.includes('login')) {
        this.logout();
      }
    },
    showModalFormDefault(data) {
      this.modalData = data
      this.open = true;
    }
  },
  watch: {
    alertMessage() {
      this.showAlert(this.alertMessage);
    },
    tenantID(tenantID) {
      if (!tenantID) {
        this.$router.push('/login')
      }
    },
    PORTAL() {
      this.checkPortal();
    }
  },
  async mounted() {
    this.modulesStore = useModulesStore()
    await this.modulesStore.initModules
    this.checkPortal();
    EventBus.$on('show-modal-form-default', this.showModalFormDefault);
    setTimeout(async () => {
      this.pinnedModuleList = await this.getPinnedModuleList()
    }, 1000);
  },
  beforeDestroy() {
    EventBus.$off('show-modal-form-default', this.showModalFormDefault);
  },
};
